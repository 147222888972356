// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-article-layout-js": () => import("/var/www/pixelteh3.0/src/layouts/ArticleLayout.js" /* webpackChunkName: "component---src-layouts-article-layout-js" */),
  "component---src-pages-blog-js": () => import("/var/www/pixelteh3.0/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("/var/www/pixelteh3.0/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-policy-js": () => import("/var/www/pixelteh3.0/src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-index-js": () => import("/var/www/pixelteh3.0/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("/var/www/pixelteh3.0/src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-team-js": () => import("/var/www/pixelteh3.0/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-technologies-android-js": () => import("/var/www/pixelteh3.0/src/pages/technologies-android.js" /* webpackChunkName: "component---src-pages-technologies-android-js" */),
  "component---src-pages-technologies-blockchain-js": () => import("/var/www/pixelteh3.0/src/pages/technologies-blockchain.js" /* webpackChunkName: "component---src-pages-technologies-blockchain-js" */),
  "component---src-pages-technologies-crossplatform-js": () => import("/var/www/pixelteh3.0/src/pages/technologies-crossplatform.js" /* webpackChunkName: "component---src-pages-technologies-crossplatform-js" */),
  "component---src-pages-technologies-full-stack-js": () => import("/var/www/pixelteh3.0/src/pages/technologies-full-stack.js" /* webpackChunkName: "component---src-pages-technologies-full-stack-js" */),
  "component---src-pages-technologies-ios-js": () => import("/var/www/pixelteh3.0/src/pages/technologies-ios.js" /* webpackChunkName: "component---src-pages-technologies-ios-js" */),
  "component---src-pages-technologies-machine-learning-js": () => import("/var/www/pixelteh3.0/src/pages/technologies-machine-learning.js" /* webpackChunkName: "component---src-pages-technologies-machine-learning-js" */),
  "component---src-pages-technologies-js": () => import("/var/www/pixelteh3.0/src/pages/technologies.js" /* webpackChunkName: "component---src-pages-technologies-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/var/www/pixelteh3.0/.cache/data.json")

